// Copyright © 2019 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

$fh = 'calc(%s - 1px)' % $footer-height

.footer
  border-normal('top')
  display: flex
  justify-content: space-between
  align-items: center
  color: $tc-subtle-gray
  flex: none
  background-color: white
  box-sizing: border-box
  
  +media-query-min($bp.xs)
    padding-left: $cs.xs
    height: $footer-height
  +media-query($bp.xs)
    min-height: $footer-height * 2
    flex-direction: column
  
  .language
    position: relative
    height: 100%

    &-dropdown
      bottom: $fh
      left: 0
  
  &.transparent
    +media-query-min($bp.s)
      .footer-section
        border: 0
        background-color: transparent

        &.interactive:hover
          background-color: rgba(255,255,2555,0.1)

      .right
          color: white

.link
  text-decoration: none

  &:first-of-type
    margin-left: 0

  &:hover
    color: $tc-deep-gray

.copyright-links
  +media-query($bp.m)
    display: none

.left
  height: $fh

.left, .right
  display: flex
  align-items: center

.right
  flex-flow: wrap
  +media-query($bp.xs)
    border-normal('top')
    width: 100%
    justify-content: space-between
    align-items: center
  
.footer-section
  height: $fh
  display: flex
  align-items: center

  +media-query($bp.xs)
    flex-grow: 1
    justify-content: center

  &:not(.interactive)
    padding: 0 $cs.s
  
  &-button
    reset-button()
    display: flex
    align-items: center
    padding: 0 $cs.s
    height: 100%

    & > div
      display: flex
      align-items: center
    
  &:not(.primary)
    border-normal('left')
    background-color: white
    &.interactive:hover
      background-color: $c-backdrop

  &.primary
    background-color: $c-active-blue
    color: white
    
    &.interactive:hover
      background-color: hoverize($c-active-blue)
